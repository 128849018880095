
export default {
  name: 'AtomRadio',
  components: {},
  props: {
    id: {
      required: false,
      type: String,
      default: ''
    },
    label: {
      required: false,
      type: String,
      default: ''
    },
    dataText: {
      required: false,
      type: String,
      default: 'GLA'
    },
    name: {
      required: false,
      type: String,
      default: 'checkbox'
    },
    box: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    isChecked: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  methods: {
    onRadioClick(event) {
      this.$emit('get-value', event.target.value)
      this.$emit('get-data-text', event.target.dataset.text)

      const radioButton = this.$el
      this.$el.parentNode.querySelectorAll('.hide-select').forEach((input) => {
        if (radioButton.getAttribute('data-box') === 'no-visit') {
          input.classList.remove('active')
        }
        if (
          input.getAttribute('data-tab') === `select-${radioButton.getAttribute('data-box')}` &&
          !input.classList.contains('active')
        ) {
          input.classList.add('active')
        } else {
          input.classList.remove('active')
        }
      })
    }
  }
}
