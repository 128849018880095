
export default {
  name: 'AtomSquare',
  props: {
    label: {
      required: false,
      type: String,
      default: ''
    },
    icon: {
      required: false,
      type: String,
      default: ''
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    }
  }
}
