
export default {
  name: 'AtomTitleAndDescription',
  props: {
    title: {
      required: true,
      type: [String, Object],
      default: "",
    },
    description: {
      required: true,
      type: [String, Object],
      default: "",
    },
  },
};
